// Next
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from "react";
import AppContext from "../../src/AppContext";

// MUI
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlaceIcon from '@mui/icons-material/Place';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

// Local
import { api_post, cookieGet, log, relativeTimeFromDateTimeStr } from "utils";
import { URL, ENUM_USERTYPE, LABEL_USER_TYPE_POSITION } from '../../src/config';
import Colored from "../Reusable/Colored";
import ColoredButton from "../Reusable/ColoredButton";
import SimpleDialog from "../Reusable/SimpleDialog";
import SimpleMenu from "../Reusable/SimpleMenu";
import UserAvatar from "../User/UserAvatar";
import { ENUM_STATES } from '../../src/constants';

export default function ViewJobPostComponent({ jobPost, listing = false }) {
  // React
  const { user, setUser } = useContext(AppContext);
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Used for variables that might change locally and require rerendering (is_active)
  const [localJobPost, setLocalJobPost] = useState(jobPost);

  // Wait until user info is loaded before showing page
  useEffect(() => {
    // wait until info is ready
    if (!(user && jobPost)) {
      return;
    }

  }, [user, jobPost, localJobPost])

  // UI

  // Menu: Control
  const [anchorElementControl, setAnchorElementControlMen] = useState(null);

  // Dialog: Toggle Post
  const [dialogTogglePostOpen, setDialogTogglePostOpen] = useState(false);
  const handleTogglePost = (result) => {
    if (result === true) {
      var form = {}
      form.jwt = cookieGet("jwt")
      form.job_post_id = jobPost.id

      api_post(URL.API.TOGGLE_MY_JOB_POST, form)
        .then((res) => {
          if (res.data.success === true) {
            const updatedLocalJobPost = { ...localJobPost, is_active: !localJobPost.is_active };
            setLocalJobPost(updatedLocalJobPost)
          }
        })
        .catch(function (error) {
          log(error)
        })
    }
  };

  // Dialog: Delete Post
  const [dialogDeletePostOpen, setDialogDeletePostOpen] = useState(false);
  const handleDeletePost = (result) => {
    if (result === true) {
      var form = {}
      form.jwt = cookieGet("jwt")
      form.job_post_id = jobPost.id

      api_post(URL.API.DELETE_MY_JOB_POST, form)
        .then((res) => {
          if (res.data.success === true) {
            router.reload()
          }
        })
        .catch(function (error) {
          log(error)
        })
    }
  };

  // return
  return (
    <>
      {
        (jobPost && localJobPost) &&
        <Card className="job-container" style={{ position: 'relative', }}>
          {/* Job Title */}
          <CardHeader
            subheader={
              <Stack></Stack>
            }
            title={
              <Stack direction={"column"} spacing={1}>
                {/* Title and hidden */}
                <Stack direction={"column"} spacing={1} justifyContent="space-between">
                  {!localJobPost.is_active ? <Colored color="hidden.fg" bg="hidden.bg" variant="body1" width={"fit-content"} height={"fit-content"} p={1}>Hidden</Colored> : ""}

                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {LABEL_USER_TYPE_POSITION[jobPost.position]} Job in {jobPost.city}, {ENUM_STATES[jobPost.state]}
                  </Typography>
                </Stack>
              </Stack>
            }
          />

          {/* Job Info */}
          <CardContent >
            <Stack direction="column" spacing={3}>
              <Stack
                direction="column"
                spacing={1}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <UserAvatar user={jobPost.poster} size={50} />
                  <Typography
                    color="textPrimary"
                    variant="body"
                  >
                    {jobPost.poster.display_name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={0.5}
                >
                  <Avatar sx={{ width: 30, height: 30, bgcolor: 'transparent' }} >
                    <PlaceIcon color="info" />
                  </Avatar>

                  <Typography
                    color="textPrimary"
                    variant="body"
                  >
                    {jobPost.city}, {ENUM_STATES[jobPost.state]}
                  </Typography>
                </Stack>
              </Stack>

              <Typography
                color="textPrimary"
                variant="body"
                sx={{ wordWrap: "break-word" }}
              >
                {jobPost.short_desc}
              </Typography>

              {
                !listing &&
                <Typography
                  color="textPrimary"
                  variant="body"
                  sx={{ wordWrap: "break-word" }}
                >
                  {jobPost.long_desc}
                </Typography>
              }

              <Typography
                color="textPrimary"
                variant="body2"
                sx={{ wordWrap: "break-word" }}
              >
                <Stack direction="row" spacing={2}>
                  <Typography color="textSecondary">
                    Salary
                  </Typography>
                  <Typography>
                    ₹{jobPost.min_salary} ~ ₹{jobPost.max_salary}
                  </Typography>
                </Stack>
              </Typography>

              <Tooltip title={jobPost.creation_time} placement="bottom" arrow enterTouchDelay={0} leaveTouchDelay={5000}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  sx={{
                    width: "fit-content",
                  }}
                >
                  {relativeTimeFromDateTimeStr(jobPost.creation_time)}
                </Typography>
              </Tooltip>

            </Stack>
          </CardContent>

          {/* CONTROL AREA */}

          {/* PC: Buttons */}
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "left" : "center"}
            justifyContent="flex-end"
            m={1}
            spacing={1}
            sx={{ width: { xs: "100%", lg: "100%" } }}
          >
            {/* Both */}
            {
              listing &&
              <Button href={URL.WEB.VIEW_JOB_POST(jobPost.id)} variant="contained">
                View Full Post
              </Button>
            }

            {/* PROFESSIONAL */}
            {
              user.type === ENUM_USERTYPE.PROFESSIONAL && (
                jobPost.user_application ?
                  <Button href={URL.WEB.VIEW_JOB_APPLICATION(jobPost.user_application.id)} variant="contained">
                    View Your Application
                  </Button>
                  : jobPost.position == user.position &&
                  <ColoredButton color="apply" href={URL.WEB.APPLY_TO_JOB(jobPost.id)}>
                    Apply to Job
                  </ColoredButton>
              )
            }

            {/* EMPLOYER: Control Buttons - PC */}
            {
              <>
                {user.type === ENUM_USERTYPE.EMPLOYER && user.id === jobPost.poster_id && jobPost.applications.length > 0 &&
                  <Button href={URL.WEB.VIEW_JOB_APPLICATIONS(jobPost.id)} variant="contained">
                    View Applications ({jobPost.applications.length})
                  </Button>
                }
                {
                  !isMobile &&
                  user.type === ENUM_USERTYPE.EMPLOYER && user.id === jobPost.poster_id &&
                  <>
                    {
                      <Button href={URL.WEB.EDIT_JOB_POST(jobPost.id)} variant="contained">
                        Edit
                      </Button>
                    }
                    {
                      <div>
                        <ColoredButton color={localJobPost.is_active ? "delete" : "apply"} onClick={() => setDialogTogglePostOpen(true)}>
                          {localJobPost.is_active ? "Hide" : "Unhide"}
                        </ColoredButton>
                      </div>
                    }
                    {
                      <div>
                        <ColoredButton color="delete" onClick={() => setDialogDeletePostOpen(true)}>
                          Delete
                        </ColoredButton>
                      </div>
                    }
                  </>
                }
              </>
            }
          </Stack>

          {/* Mobile: Menu */}
          {
            isMobile &&
            user.type === ENUM_USERTYPE.EMPLOYER && user.id === jobPost.poster_id &&
            <>
              <Tooltip title="Manage" style={{ position: 'absolute', top: 8, right: 8 }}>
                <IconButton onClick={(event) => setAnchorElementControlMen(event.currentTarget)} sx={{ p: 0 }}>
                  <MoreVertIcon color="primary" style={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
              <SimpleMenu anchorElement={anchorElementControl} setAnchorElement={setAnchorElementControlMen} mt={4}>
                <MenuItem key={"Edit"} onClick={(event) => setAnchorElementControlMen(null)}>
                  <Button href={URL.WEB.EDIT_JOB_POST(jobPost.id)} variant="contained" sx={{ width: "100%", px: 3 }}>
                    Edit
                  </Button>
                </MenuItem>

                <MenuItem key={"Hide"} onClick={(event) => setAnchorElementControlMen(null)}>
                  <ColoredButton color={localJobPost.is_active ? "delete" : "apply"} onClick={() => setDialogTogglePostOpen(true)} sx={{ width: "100%", px: 3 }}>
                    {localJobPost.is_active ? "Hide" : "Unhide"}
                  </ColoredButton>
                </MenuItem>

                <MenuItem key={"Delete"} onClick={(event) => setAnchorElementControlMen(null)}>
                  <ColoredButton color="delete" sx={{ width: "100%", px: 3 }} onClick={() => setDialogDeletePostOpen(true)}>
                    Delete
                  </ColoredButton>
                </MenuItem>
              </SimpleMenu>
            </>
          }

          {/* Dialoges */}
          {
            user.type === ENUM_USERTYPE.EMPLOYER && user.id === jobPost.poster_id &&
            <>
              <SimpleDialog
                title="Toggle Job Post?"
                text="Are you sure you want toggle your job post?"
                labelNo="Cancel"
                labelYes={localJobPost.is_active ? "Hide Post" : "Activate Post"}
                open={dialogTogglePostOpen}
                setOpen={setDialogTogglePostOpen}
                onClose={handleTogglePost} />

              <SimpleDialog
                title="Delete Job Post?"
                text="Are you sure you want to delete your job post?"
                labelNo="Cancel"
                labelYes="Delete Post"
                open={dialogDeletePostOpen}
                setOpen={setDialogDeletePostOpen}
                onClose={handleDeletePost} />
            </>
          }

          {/* CONTROL AREA */}

        </Card >
      }
    </>
  );
}
