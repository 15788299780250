import { Typography } from "@mui/material";

export default function Colored({ children, color, variant="span", bg = null, ...props }) {
    return (
        <Typography
            variant={variant}
            sx={{
                color: color,
                backgroundColor: bg
            }}
            {...props}
        >
            {children}
        </Typography>
    );
}