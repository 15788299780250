import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

export default function ColoredButton(props) {
    const { color, sx = {}, ...rest } = props;
    const theme = useTheme();

    // These colors are defined inside theme.js
    // They must contain 3 properties:
    // fr: txt color, bg: background color, bgHover: background color when hovered
    const buttonStyles = {
        color: theme.palette[color].fg,
        backgroundColor: theme.palette[color].bg,
        "&:hover": {
            backgroundColor: theme.palette[color].bgHover
        },
        ...sx
    };

    return (
        <Button variant="contained" sx={buttonStyles} {...rest} />
    );
}