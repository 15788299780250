import { Button, Menu, MenuItem, Typography } from "@mui/material";
import Link from '../../src/Link';

export default function SimpleMenu({ anchorElement, setAnchorElement, menuPages = null, children = null, mt = 3 }) {
    // This is used route the user if he clicks on the menu item but not the <Link> element inside of it
    const onMenuItemClick = (event, url) => {
      setAnchorElement(null);
    };
  
    return (
      <Menu
        sx={{ mt: mt, padding: 4 }}
        id="menu"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            padding: '0.5rem',
          },
        }}
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
      >
        {
          menuPages && menuPages.map((page) => (
            <MenuItem key={page.title} onClick={(event) => onMenuItemClick(event, "")}>
              {page.icon}
  
              <Button onClick={page.onClick ? () => page.onClick() : null} >
                <Link href={page.href ?? "#"} underline="none">
                  {page.title}
                </Link>
              </Button>
            </MenuItem>
          ))
        }
  
        {children}
      </Menu>
    )
  }