import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SimpleDialog({ title, text, labelYes = "OK", labelNo = null, open, setOpen, onClose = null }) {
  const handleYes = () => {
    setOpen(false);

    if (onClose) {
      onClose(true);
    }
  };

  const handleNo = () => {
    setOpen(false);

    if (onClose) {
      onClose(false);
    }

  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {
          labelNo &&
          <Button onClick={handleNo}>
            {labelNo}
          </Button>
        }

        <Button onClick={handleYes} autoFocus>
          {labelYes}
        </Button>
      </DialogActions>

    </Dialog>
  );
}